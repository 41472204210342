import React, {useEffect} from 'react';
import {useLocalStorage} from "@uidotdev/usehooks";
import {useQuery} from "@tanstack/react-query";
import {getCurrentUser} from "../../services/Api";
import {useAuthContext} from "../../hooks/useAuthContext";
import {RxPerson} from "react-icons/rx";
import {Chip} from "@nextui-org/react";

function User(props) {
    const [user, setUser] = useLocalStorage('user', {})
    const [token, setToken] = useLocalStorage('authToken', '')
    const {dispatch} = useAuthContext()
    const {data, isError, isLoading, isFetched} = useQuery(
        {
            queryKey: ['user'],
            queryFn: () => getCurrentUser(token),
        })

    useEffect(() => {
        // setUser(data&&data[0])
            dispatch({
                type: "LOGIN",
                payload: token,
                user: data&&data[0]
            })
    },[data])
    return (
        <div className="flex flex-wrap justify-center items-center py-4 gap-1">
            <Chip
                variant="flat"
                color="primary"
                className=" md:text-sm sm:text-xs text-xs  border-primary bg-primary-50"
            >
                <RxPerson />
            </Chip>
            <div className="flex flex-wrap">
                <p className="md:text-sm sm:text-xs text-xs text-primary text-center font-light overflow-ellipsis">
                    {data&&data[0]?.first_name}
                </p>
            </div>
        </div>
    );
}

export default User;