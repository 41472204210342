import React, {useState} from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import {useAuthContext} from "../hooks/useAuthContext";
import {useLocalStorage} from "@uidotdev/usehooks";


export const RequireAuth = ({children}) => {
    const location = useLocation();
    const [token, setToken] = useLocalStorage('authToken')
    if(!token){
        return <Navigate to='/login' state={{from: location}}/>
    }
  return children
}
