import React, {useMemo} from 'react';
import {useQuery} from "@tanstack/react-query";
import {getClients} from "../../services/Api";
import {useLocalStorage} from "@uidotdev/usehooks";
import {Spinner} from "@nextui-org/react";
import {Select} from "antd";


function ClientsList({setClient}) {
    const [token, setToken] = useLocalStorage('authToken')

    const {isLoading, isError, data: clients, error} = useQuery({
        queryKey: ['clients'],
        queryFn: async () => getClients(token && token),
    })

    if (isLoading) {
        return (
            <>
                <p className="text-primary-100 test-md">Загрузка</p>
                <Spinner>
                </Spinner>
            </>
        )
    }
    if (isError) {
        return <div className="text-center">Ошибка: {error.message}</div>
    }
    return (

            <Select
                style={{
                    width: "100%",
                }}
                showSearch

                onChange={(value, label) => setClient({id: value, value: label })}
                placeholder="Выбрать клиента"
                optionFilterProp="children"
                options={[...clients.map((client) => {
                    let value = client.id
                    let label = client.first_name
                    return {value, label}
                })]}
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }

            />

    );
}

export default ClientsList;