export const FormSteps = [
    {
        id: 1,
        name: 'Календарь',
    },
    {
        id: 2,
        name: 'Инфо'
    },
    {
        id: 3,
        name: 'Подтверждение'
    },
    {
        id: 4,
        name: 'Выполнение заказа'
    }
]