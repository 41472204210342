import Cookies from "js-cookie";
import axios from "axios";
import {API_URL} from "./Server";

export const getProducts = async (token) => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
            Authorization: `Token ${token}`
        }

    }
    const response = await axios.get(
        `${API_URL}/products/`,
        config
    )
    return response.data
}

export const getCurrentUser = async (token) => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
            Authorization: `Token ${token}`
        }

    }
    const response = await axios.get(
        `${API_URL}/users/`,
        config
    )
    return response.data
}

export const logoutUser = () => {
    const token = localStorage.getItem("authToken")
    return fetch(`${API_URL}/auth/token/logout/`,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
            "Authorization": `Token ${token}`,

        },
        body: token
    }).then((res) => {
        if (res.status === 204) {
            localStorage.removeItem("authToken");
            return res;
        }
        return "Error";
    });
};

export const getClients = async (token) => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
            Authorization: `Token ${token}`
        }

    }
    const response = await axios.get(
        `${API_URL}/clients/`,
        config
    )
    return response.data
}