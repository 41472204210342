import React from 'react';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
} from "@nextui-org/react";

function RegisterModal(props) {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    return (

        <>
            <Button
                color="primary"
                size="sm"
                type="button"
                variant="light"
                onPress={() => onOpen()}
            >
                Нет аккаунта?
            </Button>

            <Modal
                backdrop="opaque"
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                classNames={{
                    backdrop:
                        "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
                }}
            >
                <ModalContent>
                    {
                        (onClose) => (
                            <>
                                <ModalHeader>
                                    Информация о регистрации
                                </ModalHeader>
                                <ModalBody>
                                    <div className="flex justify-center items-center">
                                        <p className="text-center font-light md:text-md sm:text-sm text-gray-700">
                                            Для регистрации аккаунта в системе обратитесь к менеджеру по номеру <a
                                            href="tel:+79261305889">+7(926) 130 58 89</a></p>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                </ModalFooter>
                            </>
                        )
                    }
                </ModalContent>
            </Modal>
        </>
    );
}

export default RegisterModal;