import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Layout from "./components/page-constuction/Layout";
import {RequireAuth} from "./hoc/RequireAuth";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import ManagerLogin from "./pages/manager/ManagerLogin";

const router = createBrowserRouter(createRoutesFromElements(
        <>
            <Route path="/" element={<Layout/>}>
                <Route path="/" element={
                    <RequireAuth>
                        <Home/>
                    </RequireAuth>
                }/>
            </Route>
            <Route path="/login" element={<Login/>}/>
            <Route path="/manager" element={<ManagerLogin/>}/>
            <Route path="*" element={<NotFoundPage/>} />
        </>
    )
)

function App() {
    return (<RouterProvider router={router}/>);
}

export default App;
