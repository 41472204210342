export default function SearchComponent({setData}) {
    return (

        <form>
            <label
                className="mb-2 text-sm font-medium text-gray-900 sr-only ">Search</label>
            <div className="relative">
                <div
                    className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none focus:border-primary">
                    <svg className="w-4 h-4 text-gray-500 " aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input
                    onChange={(e) => setData(e.target.value)}
                    className="block w-full p-4 ps-10 text-sm bg-zinc-50 text-gray-900 border border-gray-300 rounded-md hover:border-primary focus:border-blue-500 outline-none"
                    placeholder="Поиск"/>
            </div>

        </form>

    );
}