import React from 'react';
import PageNav from "./PageNav";
import {Outlet} from "react-router-dom";
import PageFooter from "./PageFooter";
import styles from "./style.module.css"

function Layout() {
    return (
        <div className={styles.layout}>
            <PageNav/>
            <Outlet/>
            <PageFooter/>
        </div>
    );
}

export default Layout;