import dayjs from "dayjs";

export const changeTime = (sourceDate, destinationDate) => {
  const hour = sourceDate.getHours();
  const minute = sourceDate.getMinutes();
  return dayjs(destinationDate).set("hour", hour).set("minute", minute);
};

export const hoursRange = (start, end) => {
  const arr = [];
  let i = start;
  while (i < end) {
    arr.push(i);
    i++;
  }
  return arr;
}
