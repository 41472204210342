import {AuthContext} from "../hoc/AuthProvider";
import {useContext} from "react";

export const useAuthContext = () => {
    const context = useContext(AuthContext)

    if (!context){
        throw Error('Проблема с контекстом авторизации')
    }

    return context
}