import React, {useEffect} from 'react';
import {Button, Image, Navbar, NavbarBrand, NavbarContent, NavbarItem} from "@nextui-org/react";
import {useLocalStorage} from "@uidotdev/usehooks";
import User from "../user/User";
import {useAuthContext} from "../../hooks/useAuthContext";
import {RxExit} from "react-icons/rx";
import { IoBarChartOutline } from "react-icons/io5";
import {logoutUser} from "../../services/Api";
import {useNavigate} from "react-router-dom";

function PageNav() {
    const {user, auth, dispatch} = useAuthContext()
    const navigate = useNavigate()
    const handleLogout = () => {
        dispatch({
            type: 'LOGOUT',
            payload: null,
            user: null
        })
        localStorage.removeItem('authToken')
        navigate("/login")
    }
    return (
        <>
            <Navbar isBlurred isBordered height={"auto"}>
                <NavbarContent justify={"start"}>
                    <NavbarBrand className=" px-2 ">
                        <Image width={120} src="logo.png"></Image>
                    </NavbarBrand>
                </NavbarContent>
                <NavbarContent>
                    <User />
                </NavbarContent>
                <NavbarContent justify={"end"}>
                    <NavbarItem>
                        {
                            user?.is_staff ?
                                <Button onPress={() => window.open("http://79.137.254.146:5557/reports/?id=684c0194-1d2f-4273-b1cf-0343820699aa", '_blank') }
                                    size="sm" color="warning" variant="flat">
                                    Отчёт
                                    <span>
                                        <IoBarChartOutline />
                                    </span>
                                </Button>
                                :
                                <></>
                        }

                    </NavbarItem>
                    <NavbarItem>
                        <Button onPress={handleLogout} size="sm" variant="flat">
                            { user?.username ? "Выход" : "Войти"}{" "}
                            <span>
              <RxExit />
            </span>
                        </Button>
                    </NavbarItem>
                </NavbarContent>
            </Navbar>
        </>
    );
}

export default PageNav;