import React, {useMemo, useState} from 'react';
import {DateRange} from "react-date-range";
import {ru} from "react-date-range/dist/locale";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {Input, TimePicker} from "antd";
import dayjs from "dayjs";
import {changeTime, hoursRange} from "../../services/DateHelper";
import TextArea from "antd/es/input/TextArea";
import {useMutation} from "@tanstack/react-query";
import {json} from "react-router-dom";
import Message from "../message/Message";

function ModalFormBody({
                           product,
                           currentStepIndex,
                           dateRangeState,
                           setDateRangeState,
                           orderOptions,
                           setOrderOptions,
                           start,
                           setStart,
                           end,
                           setEnd,
                           message,
                           error
                       }) {

    const eventList = useMemo(() => {
        const resultArray = []
        if (product.events.length > 0 && product.events) {
            product.events.forEach((item) => {
                    let itemDate = dayjs(item.date_from)
                    resultArray.push(itemDate.$d)
                }
            )
        }
        return resultArray
    }, [product])

    
    const setComment = (e) => {
        setOrderOptions((existingValues) => ({
            ...existingValues,
            comment: e.target.value,
        }));
    };

    const setAddress = (e) => {
        setOrderOptions((existingValues) => ({
            ...existingValues,
            address: e.target.value,
        }));
    };

    const handleTime = (value, funcName) => {
        if (funcName === "start") {
            let time = value
            setStart(dayjs(time))
            const updStartDate = changeTime(value.$d, dateRangeState[0].startDate)
            setStart(updStartDate)
        }
        if (funcName === "end") {
            let time = value
            setEnd(dayjs(time))
            const updEndDate = changeTime(value.$d, dateRangeState[0].endDate)
            setEnd(updEndDate)
        }
    }

    switch (currentStepIndex) {
        case 0:
            return (
                <>
                    <div className="flex w-full gap-2 justify-center">
                        <DateRange
                            onChange={(item) => setDateRangeState([item.selection])}
                            months={2}
                            locale={ru}
                            ranges={dateRangeState}
                            direction="vertical"
                            disabledDates={eventList}
                            minDate={new Date()}
                            showMonthArrow={false}
                        />
                    </div>
                </>
            )
        case 1:
            return (
                <>
                    <div className="flex flex-wrap justify-start gap-3 mt-3">
                        <div className="flex w-full justify-start gap-2">
                            <TimePicker
                                getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode
                                }
                                }
                                showTime={{format: "HH:mm"}}
                                format="HH:mm"
                                changeOnBlur={true}
                                inputReadOnly={true}
                                placeholder={"Время начала аренды"}
                                allowClear={false}
                                value={start}
                                onChange={(time) => handleTime(time, "start")}
                                hideDisabledOptions={true}
                                disabledHours={() => hoursRange(0, 8)}
                                minuteStep={5}
                            />
                            <TimePicker
                                getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode
                                }
                                }
                                showTime={{format: "HH:mm"}}
                                format="HH:mm"
                                changeOnBlur={true}
                                inputReadOnly={true}
                                placeholder={"Время завершения аренды"}
                                allowClear={false}
                                value={end}
                                onChange={(time) => handleTime(time, "end")}
                                hideDisabledOptions={true}
                                disabledHours={() => hoursRange(0, 8)}
                                minuteStep={5}
                            />
                        </div>
                        <Input
                            placeholder="Адрес"
                            value={orderOptions.address}
                            onChange={setAddress}
                        />
                        <TextArea
                            showCount
                            maxLength={100}
                            style={{height: 120, marginBottom: 24}}
                            placeholder="Комментарий"
                            value={orderOptions.comment}
                            onChange={setComment}
                        />
                    </div>
                </>
            )
        case 2:
            return (
                <>
                    <div className="flex w-full flex-col gap-2 p-2 border-1 rounded-md bg-zinc-100 border-dashed mb-4">
                        <div className="flex w-full justify-between gap-2 pb-2 border-b border-dashed items-center">
                            <div className="flex w-1/2 gap-2 justify-start">
                                <p className="text-xs text-accent text-zinc-400 font-bolt">Аппарат:</p>
                            </div>
                            <div className="flex w-1/2 justify-center items-center">
                                <p className="text-xs">{product.name}</p>
                            </div>
                        </div>
                        <div className="flex w-full justify-between pb-2 border-b border-dashed gap-2 items-center">
                            <div className="flex w-1/2 gap-2 justify-start">
                                <p className="text-xs text-accent text-zinc-400 font-bolt">Период аренды:</p>
                            </div>
                            <div className="flex w-1/2 justify-center items-center">
                                <p className="text-xs text-center">
                                    c {dayjs(dateRangeState[0].startDate).format("DD.MM.YYYY")} г. по{" "}
                                    {dayjs(dateRangeState[0].endDate).format("DD.MM.YYYY")} г.
                                </p>
                            </div>
                        </div>
                        <div className="flex w-full justify-between gap-2 items-center">
                            <div className="flex w-1/2 gap-2 justify-start">
                                <p className="text-xs text-accent text-zinc-400 font-bolt">Адрес:</p>
                            </div>
                            <div className="flex w-1/2 justify-center items-center">
                                <p className="text-xs text-center">
                                    {orderOptions.address}
                                </p>
                            </div>
                        </div>

                    </div>
                </>
            )
        case 3:
            return (
                <div className="flex justify-center items-center py-3">
                    <Message message={message} status={error} />
                </div>
            )
    }
}

export default ModalFormBody;