import React, {useEffect, useMemo, useState} from 'react';
import {Button, Popover, PopoverContent, PopoverTrigger} from "@nextui-org/react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure} from "@nextui-org/react";
import {FormSteps} from "./FormSteps";
import {useMultistep} from "../../hooks/useMultiStep";
import ModalFormHeader from "./ModalFormHeader";
import ModalFormBody from "./ModalFormBody";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import {API_URL} from "../../services/Server";
import {useLocalStorage} from "@uidotdev/usehooks";
import dayjs from "dayjs";
import {RxCaretRight} from "react-icons/rx";


function ModalForm({product, user}) {
    const [token, setToken] = useLocalStorage('authToken')
    const [dateRangeState, setDateRangeState] = useState([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
            },
        ]
    )
    const [orderOptions, setOrderOptions] = useState({
        comment: "",
        address: "",
    })
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [errorDate, setErrorDate] = useState(false)
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [error, setError] = useState(false)
    const queryClient = useQueryClient()
    const {currentStepIndex, step, back, next, goTo} = useMultistep(FormSteps)

    useEffect(() => {
        if (product.events && product.events.length > 0)
        {
           const findDate = product.events.find((closedDate) =>
                dayjs(closedDate.date_from).isSame(dateRangeState[0].startDate, 'day', 'month', 'year')
            )
            if(findDate) {
                setErrorDate(true)
            }
            else {
                setErrorDate(false)
            }


        }
    }, [product, dateRangeState])

    const mutation = useMutation((makeOrder) => {
            const config = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
            const body = JSON.stringify({
                external_key: "none",
                date_from: start.$d || dateRangeState[0].startDate,
                date_to: end.$d || dateRangeState[0].endDate,
                status: 0,
                user: user.id,
                product: product.id,
                comment: orderOptions.comment,
                address: orderOptions.address
            })
            return axios.post(`${API_URL}/orders/`, body, config)
        }
    )
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        mutation.mutate('makeOrder', {
            onSuccess: (data, variables, context) => {
                setIsLoading(false)
                queryClient.invalidateQueries({queryKey: ['products']})
                setMessage("Заказ успешно оформлен, в ближайшее время c Вами свяжется менеджер")
                setError(false)
                next()

            },
            onError: () => {
                setIsLoading(false)
                setMessage("Произошла ошибка, попробуйте оформить заказ позже или свяжитесь с менеджером")
                setError(true)
                next()
            }
        })
    }


    const handleOpen = () => {
            onOpen();
            goTo(0);
    }
    return (
        <>
            {/*<Button*/}
            {/*    color="primary"*/}
            {/*    radius="full"*/}
            {/*    size="md"*/}
            {/*    variant="shadow"*/}
            {/*    onPress={handleOpen}*/}
            {/*>*/}
            {/*    Заказать*/}
            {/*</Button>*/}
            <OpenButton user={user} handleOpen={handleOpen} />
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} scrollBehavior="inside">
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                <ModalFormHeader currentStepIndex={currentStepIndex}
                                                 back={back}
                                                 next={next}
                                                 handleSubmit={handleSubmit}
                                                 isLoading={isLoading}
                                                 errorDate={errorDate}
                                />
                            </ModalHeader>
                            <ModalBody>
                                <ModalFormBody
                                    product={product}
                                    currentStepIndex={currentStepIndex}
                                    dateRangeState={dateRangeState}
                                    setDateRangeState={setDateRangeState}
                                    orderOptions={orderOptions}
                                    setOrderOptions={setOrderOptions}
                                    start={start}
                                    setStart={setStart}
                                    end={end}
                                    setEnd={setEnd}
                                    message={message}
                                    error={error}
                                />
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
const OpenButton = ({user ,handleOpen}) => {
    if(user&&user.id !== 0){
        return (
            <Button
                color="primary"
                radius="lg"
                size="md"
                variant="shadow"
                onPress={handleOpen}
            >
                Заказать
            </Button>
        )}
    else {
        return (
            <Popover placement="bottom" showArrow={true}>
                <PopoverTrigger>
                    <Button
                        color="primary"
                        radius="lg"
                        size="md"
                        variant="shadow"
                    >
                        Заказать
                    </Button>
                </PopoverTrigger>
                <PopoverContent>
                    <div className="px-1 py-2">
                        <div className="text-tiny">Клиент не выбран</div>
                    </div>
                </PopoverContent>
            </Popover>
        )

    }
}

export default ModalForm;