import React from 'react';
import {RxCaretLeft, RxCaretRight} from "react-icons/rx"
import {Popover, PopoverTrigger, PopoverContent, Button} from "@nextui-org/react";

function ModalFormHeader({currentStepIndex, next, back, handleSubmit, isLoading, errorDate}) {
    switch (currentStepIndex) {
        case 0:
            return (
                <>
                    <p className="text-primary text-center">Оформление заказа</p>
                    <div className="flex justify-between items-center flex-row gap-1 pt-2">
                        <div className="flex justify-start w-1/2">
                        </div>
                        <div className="flex w-1/2 justify-end">
                            {
                                !errorDate?
                                    <Button variant="flat" color="success" onPress={ next}>
                                        <p className="flex flex-nowrap justify-between items-center">Далее <span
                                            className="text-2xl"><RxCaretRight/></span></p>
                                    </Button>
                                    :
                                    <Popover placement="bottom" showArrow={true}>
                                        <PopoverTrigger>
                                            <Button variant="flat" color="success">
                                                <p className="flex flex-nowrap justify-between items-center">Далее <span
                                                    className="text-2xl"><RxCaretRight/></span></p>
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <div className="px-1 py-2">
                                                <div className="text-tiny">Выберите свободные даты</div>
                                            </div>
                                        </PopoverContent>
                                    </Popover>

                            }

                        </div>
                    </div>
                </>
            )
        case 1:
            return (
                <>
                    <p className="text-primary text-center">Дополнительная информация</p>
                    <div className="flex justify-between items-center flex-row gap-1 pt-2">
                        <div className="flex justify-start w-1/2">
                            <Button className="flex justify-start text-zinc-500" variant="light" onPress={back}>
                                <p className="flex flex-nowrap justify-start items-center"><span
                                    className="text-2xl"><RxCaretLeft/></span>Назад</p>
                            </Button>
                        </div>
                        <div className="flex w-1/2 justify-end">
                            <Button variant="flat" color="success" onPress={next}>
                                Оформить
                            </Button>
                        </div>
                    </div>
                </>
            )
        case 2:
            return (
                <>
                    <p className="text-primary text-center">Подтверждение заказа</p>
                    <div className="flex justify-between items-center flex-row gap-1 pt-2">
                        <div className="flex justify-start w-1/2">
                            <Button className="flex justify-start text-zinc-500" variant="light" onPress={back}>
                                <p className="flex flex-nowrap justify-start items-center"><span
                                    className="text-2xl"><RxCaretLeft/></span>Назад</p>
                            </Button>
                        </div>
                        <div className="flex w-1/2 justify-end">
                            <form onSubmit={handleSubmit}>
                                {
                                    isLoading ? (
                                            <Button
                                                isLoading
                                                color="secondary"
                                                spinner={
                                                    <svg
                                                        className="animate-spin h-5 w-5 text-current"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        />
                                                        <path
                                                            className="opacity-75"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                }
                                            >
                                                идет обработка
                                            </Button>
                                        )
                                        :
                                        <Button variant="flat" color="success" type="submit">
                                            Подтвердить заказ
                                        </Button>
                                }
                            </form>
                        </div>
                    </div>
                </>
            )
        case 3:
            return (
                <>
                </>
            )
        default:
            return (
                <></>
            )
    }
}

export default ModalFormHeader;