import React, {useEffect, useState} from 'react';
import dayjs from "dayjs";
import styles from "./style.module.css"

function PageFooter() {
    const [year, setYear] = useState(0);
    useEffect(() => {
        const date = dayjs();
        setYear(date.year());
    }, []);
    return (
            <footer className={styles.footer}>
                <div className={styles.footerContainer}>
                    <p>© {year} Copyright:</p>
                    <a>LASER EXPERT</a>
                </div>
            </footer>
    );
}

export default PageFooter;