import React, {useState} from 'react';
import styles from './Home.module.css'
import {useQuery} from "@tanstack/react-query";
import {getProducts} from "../../services/Api";
import {useLocalStorage} from "@uidotdev/usehooks";
import {Button, Spinner} from "@nextui-org/react";
import ProductList from "../../components/product-list/ProductList";
import {useAuthContext} from "../../hooks/useAuthContext";
import ClientsList from "../../components/clients-list/ClientsList";
import SearchComponent from "../../components/search-component/SearchComponent";
import {useNavigate} from "react-router-dom";


const Home = () => {
    const [searchData, setSearchData] = useState("");
    const [client, setClient] = useState({
        id: 0,
        firstName: ""
    })
    const [token, setToken] = useLocalStorage('authToken')
    const {user} = useAuthContext()
    const navigate = useNavigate()
    const {isLoading, isError, data: products, error} = useQuery({
        queryKey: ['products'],
        queryFn: async () => getProducts(token && token),
    })
    if (isLoading) {
        return (
            <div className="flex flex-wrap py-4 px-2 mb-auto justify-center gap-4 items-center h-screen">
                <p className="text-primary-100 test-2xl">Загрузка</p>
                <Spinner>
                </Spinner>
            </div>
        )
    }

    if (isError) {
        navigate("/login")
        return <div className="text-center">Ошибка: {error.message}</div>
    }

    return (
        <main className="flex flex-wrap py-4 px-3 mb-auto">
            <div className="flex justify-center items-center pb-4 flex-col w-full">
                <div className="py-4 w-full md:w-3/4">
                    <SearchComponent setData={setSearchData}/>
                </div>
                {
                    user && user.is_staff ?
                        <div className="flex justify-center items-center pb-2 w-full md:w-3/4 gap-2">
                            <ClientsList setClient={setClient}/>
                        </div>
                        :
                        <></>
                }
            </div>

            <ProductList products={products
                ?.filter((element) =>
                    element.name
                        .toLowerCase()
                        .includes(searchData.toLowerCase()),
                )}
                         user={user && user.is_staff ? client : user}
            />
        </main>
    );
};

export default Home;