import React from 'react';
import {Result} from "antd";
import {Button} from "@nextui-org/react";
import {useNavigate} from "react-router-dom";

function NotFoundPage() {
    const navigate = useNavigate()
    return (
        <div>
            <Result
                status="404"
                title="404"
                subTitle="Извините, страница не найдена."
                extra={<Button
                    type="primary"
                    onPress={() => navigate("/")}
                >На главную</Button>}
            />
        </div>
    );
}

export default NotFoundPage;