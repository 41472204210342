import React from 'react';
import ProductItem from "../product-item/ProductItem";

function ProductList({products, user}) {
    return (
        <div className="flex flex-wrap w-full justify-center items-center gap-4">
            {
                products&&products.map((item) => (
                    <ProductItem key={item.id} product={item} user={user} />
                )
                )
            }
        </div>
    );
}

export default ProductList;