import {createContext, useEffect, useReducer, useState} from "react";

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return {
                auth: action.payload,
                user: action.user
            }
        case "LOGOUT":
            return {
                auth: null,
                user: null
            }
        default:
            return state
    }
}

export const AuthContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(authReducer, {
        auth: null,
        user: null
    })

    useEffect(() => {
        const auth = JSON.parse(localStorage.getItem('authToken'))
        //const user = JSON.parse(localStorage.getItem('user'))
        if (auth) {
            dispatch({type: "LOGIN", payload: auth})
        }

    }, [])
    return (
        <AuthContext.Provider value={{...state, dispatch}}>
            {children}
        </AuthContext.Provider>
    )
}