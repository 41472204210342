import React from 'react';
import {Button, Card, CardFooter, CardHeader, Chip} from "@nextui-org/react";
import {Image} from "@nextui-org/react";
import styles from "./style.module.css"
import ModalForm from "../modal-form/ModalForm";

function ProductItem({product, user}) {
    return (
        <Card key={product.id}
              isFooterBlurred
              className={styles.productCard}>
            <CardHeader className="absolute z-10 top-1 flex-col items-start">
                <Chip
                    variant="shadow"
                    className=" border-purple-600 bg-purple-100/50 text-xl text-purple-800 text-center">
                    <h4>{product.name}</h4>
                </Chip>
            </CardHeader>
            <Image
                removeWrapper
                alt={product.name}
                className="z-0 h-full object-cover object-top"
                src={product.image || 'empty.png'}
            />
            <CardFooter
                className="justify-between before:bg-white/10 border-white/20 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_15px)] shadow-small mb-1 ml-2 z-10">
                <div className="flex justify-start w-full">
                    <p className="text-blue-800/80 px-1 text-xs">{product.description}</p>
                </div>
                <div className="flex justify-end">
                {product.booking_disable ?
                    <p className="text-sm text-purple-400 font-bolt">Временно недоступен</p>
                    :
                    <ModalForm product={product} user={user}/>
                }
                </div>

            </CardFooter>
        </Card>
    );
}

export default ProductItem;