import React, {useState} from 'react';
import {useLocalStorage} from "@uidotdev/usehooks";
import {useNavigate} from "react-router-dom";
import {Input, message} from "antd";
import {useAuthContext} from "../../hooks/useAuthContext";
import {useMutation} from "@tanstack/react-query";
import Cookies from "js-cookie";
import axios from "axios";
import {API_URL} from "../../services/Server";
import styles from "../login/Login.module.css";
import PhoneInput from "antd-phone-input";

function ManagerLogin(props) {
    const [username, setUsername] = useState("")
    const [token, setToken] = useLocalStorage('authToken', '')
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [password, setPassword] = useState("")
    const {dispatch} = useAuthContext()
    const mutation = useMutation((singInManager) => {
        const config = {
            method: 'POST', headers: {
                "Content-Type": "application/json", "X-CSRFToken": Cookies.get("csrftoken"),
            },
        }
        const body = JSON.stringify({username, password})
        return axios.post(`${API_URL}/auth/token/login/`, body, config)
    })

    const errorMessage = () => {
        messageApi.open({
            type: 'error', content: 'Ошибка: Логин некорректный',
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        mutation.mutate('singInManager', {
            onSuccess: (data, variables, context) => {
                setToken(data && data.data.auth_token)
                dispatch({
                    type: "LOGIN",
                    payload: token,
                })
                navigate('/')
            }, onError: () => {
                errorMessage()
            }
        })

    }

    return (<div className={styles.mainContent}>
        {contextHolder}
        <div
            className={styles.innerContent}>
            <img className="p-6" src="logo.png" alt="laser-expert"></img>
            <h1 className="text-3xl font-semibold text-center uppercase text-blue-800">
                Вход в личный кабинет менеджера
            </h1>
            <form onSubmit={handleSubmit} className="mt-6">
                <div className="mb-2">
                    <Input
                        placeholder="Логин"
                        onChange={(e) =>
                            setUsername(e.target.value)}
                    />
                </div>
                <div className="mb-2">
                    <Input
                        placeholder="Пароль"
                        type="password"
                        onChange={(e) =>
                            setPassword(e.target.value)}
                    />
                </div>
                <div className="mt-6">
                    <button
                        type="submit"
                        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200
                            transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
                    >
                        Войти
                    </button>
                </div>
            </form>

            <p className="mt-4 text-xs font-light text-center text-gray-700">
                {" "}
                LASER EXPERT{" "}
            </p>
        </div>
    </div>);
}

export default ManagerLogin;