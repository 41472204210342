import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {NextUIProvider} from "@nextui-org/react";
import {QueryClientProvider} from '@tanstack/react-query';
import {QueryClient} from "@tanstack/react-query";
import {AuthContextProvider} from "./hoc/AuthProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient()
root.render(
    <NextUIProvider>
            <QueryClientProvider client={queryClient}>
                <AuthContextProvider>
                    <App/>
                </AuthContextProvider>
            </QueryClientProvider>
    </NextUIProvider>
);
